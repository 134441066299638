// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import axios from '../../../../utility/hooks/useAxios'

export const getClients = createAsyncThunk('appEcommerce/getClients', async params => {
    const response = await axios.get('/clients', { params })
    console.log("params----------------------------------->>>:", params)
    console.log('response client', response)
    return { params, data: response.data }
})

export const getVendors = createAsyncThunk('appEcommerce/getVendor', async () => {
    const response = await axios.get('/vendors')
    console.log('response vendor', response.data.data)
    return { data: response.data.data }
})

export const getClient = createAsyncThunk('appEcommerce/getClient', async slug => {
    const response = await axios.get(`/client/${slug}`)
    return response.data
})

export const appEClientSlice = createSlice({
    name: 'appEcommerce',
    initialState: {
        params: {},
        clients: [],
        vendors: [],
        totalClients: 0,
        clientDetail: {},
        selectedCompany: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getClients.fulfilled, (state, action) => {
                state.params = action.payload.params
                state.clients = action.payload.data.client
                state.totalClients = action.payload.data.total
            })
            .addCase(getVendors.fulfilled, (state, action) => {
                console.log("Asdasdas:", action.payload)
                state.vendors = action.payload.data
            })
            .addCase(getClient.fulfilled, (state, action) => {
                console.log(action.payload.client)
                state.clientDetail = action.payload.client
            })
    }
})

export default appEClientSlice.reducer

